<!--
.. Mofified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <new-edit-sheet />
    <delete-dialog />
    <v-row align="center" justify="space-between" no-gutters>
      <v-col cols="8">
        <settings-breadcrumbs v-model="project" />
      </v-col>
      <v-col class="text-right">
        <v-btn color="info" class="mr-2" @click="createEditShow()">
          <v-icon class="mr-1">mdi-plus</v-icon>
          New Cost Type
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-alert outlined dismissible icon="mdi-currency-usd" prominent type="info">
          <div>
            <p><strong>BAO cost types</strong> are line items for incident cost. Add your own for more accurate IAO cost estimates.</p>
            <p>Refer to the <a href="https://www.fairinstitute.org/blog/a-crash-course-on-capturing-loss-magnitude-with-the-fair-model" target="_blank" rel="noopener noreferrer">FAIR Model</a> for more information.</p>
            <p>Also, check out the new feature that is in preview (<strong>custom cost models</strong>) in the tab below, for some exciting updates.</p>
          </div>
        </v-alert>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card elevation="0">
          <v-card-title>
            <v-text-field v-model="q" append-icon="search" label="Search" single-line hide-details clearable />
          </v-card-title>
          <v-row>
            <v-col
              v-for="item in items"
              :key="item.id"
              cols="12"
              sm="6"
              md="4"
            >
              <v-card class="pa-3" outlined>
                <v-card-title>
                  <v-icon left color="warning">
                    mdi-chart-pie
                  </v-icon>
                  {{ item.name }}
                  <v-spacer></v-spacer>
                  <v-btn icon @click="createEditShow(item)">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </v-card-title>

                <v-card-text>
                  <v-chip v-if="item.default" color="secondary" dark class="mr-2">
                    <v-icon left>mdi-star</v-icon>
                    Default
                  </v-chip>
                  <v-chip color="primary" dark class="mr-2">
                    <v-icon left>mdi-chart-pie</v-icon>
                    {{ item.category }}
                  </v-chip>
                  <v-chip :color="item.editable ? 'success' : 'error'" dark class="mr-2">
                    <v-icon left>{{ item.editable ? 'mdi-table-edit' : 'mdi-lock' }}</v-icon>
                    {{ item.editable ? 'Editable' : 'Not Editable' }}
                  </v-chip>
                </v-card-text>

                <v-card-actions class="justify-end">
                  <v-btn text color="primary" @click="createEditShow(item)">Edit</v-btn>
                  <v-btn text color="error" @click="removeShow(item)">Delete</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"
import SettingsBreadcrumbs from "@/components/SettingsBreadcrumbs.vue"
import DeleteDialog from "@/incident_cost_type/DeleteDialog.vue"
import NewEditSheet from "@/incident_cost_type/NewEditSheet.vue"

export default {
  name: "IncidentCostTypeTable",

  components: {
    DeleteDialog,
    NewEditSheet,
    SettingsBreadcrumbs,
  },

  data() {
    return {
      headers: [
        { text: "Name", value: "name", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "Category", value: "category", sortable: false },
        { text: "Details", value: "details", sortable: false },
        { text: "Default", value: "default", sortable: true },
        { text: "Editable", value: "editable", sortable: true },
        { text: "Created At", value: "created_at", sortable: true },
        { text: "", value: "data-table-actions", sortable: false, align: "end" },
      ],
    }
  },

  computed: {
    ...mapFields("incident_cost_type", [
      "table.options.q",
      "table.options.page",
      "table.options.itemsPerPage",
      "table.options.sortBy",
      "table.options.descending",
      "table.options.filters.project",
      "table.loading",
      "table.rows.items",
      "table.rows.total",
    ]),
    ...mapFields("route", ["query"]),
  },

  created() {
    this.project = [{ name: this.query.project }]

    this.getAll()

    this.$watch(
      (vm) => [vm.page],
      () => {
        this.getAll()
      }
    )

    this.$watch(
      (vm) => [vm.q, vm.itemsPerPage, vm.sortBy, vm.descending, vm.project],
      () => {
        this.page = 1
        this.$router.push({ query: { project: this.project[0].name } })
        this.getAll()
      }
    )
  },

  methods: {
    ...mapActions("incident_cost_type", ["getAll", "createEditShow", "removeShow"]),
  },
}
</script>

<style scoped>
a {
  color: white !important;
}

</style>
